import { render, staticRenderFns } from "./TicketingLinksManager.vue?vue&type=template&id=5bb08dd1&scoped=true"
import script from "./TicketingLinksManager.vue?vue&type=script&lang=js"
export * from "./TicketingLinksManager.vue?vue&type=script&lang=js"
import style0 from "./TicketingLinksManager.vue?vue&type=style&index=0&id=5bb08dd1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb08dd1",
  null
  
)

export default component.exports